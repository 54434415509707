import React from 'react'
import {Container, Grid, Typography} from '@mui/material'

export default function Fotter(){


    return (
        <Container
         maxWidth="xl"
         style={{
             backgroundColor: '#000',
             minHeight : 500,
             marginTop: 100
         }}
        >
            <Grid container spacing={2} style={{color: '#fff'}}>
                <Grid item xs={12} sm={7}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.163011823507!2d91.6941818236364!3d26.158820063563734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5bcc5cfd4021%3A0x490e15217eda43f!2sOikos%20Technology%20Associate!5e0!3m2!1sen!2sin!4v1645341960559!5m2!1sen!2sin"  style={{border : 0,marginTop: 20,maxWidth: '100%',maxHeight: '350'}} allowfullscreen="" loading="lazy"></iframe>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <h2 style={{color : '#fff'}}>Address</h2>
                    <hr color='#fff' />
                    <div style={{marginLeft: 25}}>
                    <h3>Head Office</h3>
                    <Typography>Zoo Road Tiniali, Guwahati-781024, Assam, India</Typography>
                    <Typography>Email - gnosisinstitute01@gmail.com</Typography>

                   
                    </div>
                </Grid>
            </Grid>
            <Typography style={{textAlign: 'center',color: '#e77817',fontSize: 14,marginTop: 10}}>Copyright © 2022 GIST. All rights reserved.Powered by OTA</Typography>
        </Container>
    )
}