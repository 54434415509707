import React from "react";
import {  Container, Grid,Typography } from "@mui/material";
import LogoImage from '../assets/logo.jpg'
import ContactImage from '../assets/contact.png'
import colors from '../consts/colors'
export default function TopBar(){


    return(
        <div style={{padding: 5,backgroundColor : '#fff',width: '100%'}}>
            <Grid container>
                <Grid item sm={5} style={{display: 'flex'}}>
                    <img src={LogoImage} width="100" height="100" />
                    <div>
                    <h2 style={{marginLeft: 30,marginBottom: 0}}>GNOSIS INSTITUTE</h2>
                    <Typography variant="subtitle1"  style={{marginLeft : 30, color: colors.secondary}}>Never Ending Process of Learning</Typography>
                    </div>
                </Grid>
                <Grid item sm={7} style={{display:'flex',justifyContent: 'flex-end',alignItems : 'center',height : '100%'}}>
                    {/* <img src={ContactImage} width="200" height="80" /> */}
                   <div style={{marginRight : 50}}>
                   <Typography style={{fontWeight: 'bold',fontSize: 18}}>Contact</Typography>
                    <Typography>9387932607</Typography>
                    <Typography>7002714586</Typography>
                   </div>
                </Grid>
            </Grid>
        </div>
    )
}